import { useContext, useState } from "react"

// ** MUI
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { notify, style } from "../utils"

export const RelocateItemsModal = ({
  handlePendingChangesUpdate,
  shouldOpen,
  handleClose,
  itemsToRelocate,
  clearSelectedRows,
  selectedGlobalProgramId,
  handlePendingChangesCount,
}) => {
  let context = useContext(AppContext)
  const [open, setOpen] = useState(shouldOpen)
  const [warehouseId, setWarehouseId] = useState("")
  const [location, setLocation] = useState("")
  const warehousesList = context.warehouseData.map((el) => {
    return { id: el.id, label: el.buildingNumber }
  })

  const handleChange = async (event, key) => {
    if (event.target.name === "warehouse")
      setWarehouseId(key.key.slice(2)) // Remove .$ from the key.
    else if (event.target.name === "location") setLocation(event.target.value)
  }

  const handleManagementRecord = async () => {
    let response = await apiCalls.getManagementRecords(
      `?%24filter=WarehouseId%20eq%20${warehouseId}%20and%20ArmyProgramId%20eq%20${selectedGlobalProgramId}`
    )

    if (response.data.value.length === 0) {
      let body = {
        armyProgramId: selectedGlobalProgramId,
        warehouseId: warehouseId,
      }
      let post = await apiCalls.postRecord(body, "ManagementRecords")
      return post
    } else {
      return response
    }
  }

  const handleRelocateItems = async () => {
    try {
      await handleManagementRecord()
      let relocateResp = await Promise.all(
        itemsToRelocate.map(async (item) => {
          let newItem = {
            ...item,
            Location: location,
            ManagementRecordWarehouseId: warehouseId,
          }
          let putResp = await apiCalls.putPendingRecordChanges(newItem, "InventoryRecords", newItem.id)
          return putResp
        })
      )
      notify("success", "Changes have successfully been marked as pending for Administrator approval.")
      handleClose(false)
      clearSelectedRows()
      handlePendingChangesUpdate()
      handlePendingChangesCount(relocateResp?.length * 2, "Inventory")
    } catch (e) {
      console.log("ERROR:", e)
      notify("error", "The item(s) could not be relocated. Please try again.")
      handleClose(false)
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleClose(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={11}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">Relocate Item(s)</Divider>
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={11}
            sx={{ textAlign: "left" }}
          >
            Select a new warehouse and enter a location for the item(s):
          </Grid>

          {/* DROPDOWNS */}
          <Grid
            item
            xs={11}
          >
            <FormControl sx={{ m: 1, minWidth: "80%" }}>
              <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
              <Select
                id="warehouse"
                name="warehouse"
                label="Warehouse"
                onChange={handleChange}
                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              >
                {warehousesList.map((el) => {
                  return (
                    <MenuItem
                      key={el.id}
                      value={el.label}
                    >
                      {el.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={11}
          >
            <FormControl sx={{ minWidth: "80%" }}>
              <TextField
                id="location"
                name="location"
                label="Location"
                type="text"
                value={location}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={8}
          />
          <Grid
            item
            xs={3}
          >
            <Button
              variant="contained"
              component="label"
              onClick={handleRelocateItems}
              disabled={!warehouseId || location === ""}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
