// ** React
import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"

// ** MUI
import { Box, Button, Tooltip, Checkbox, FormControl, FormGroup, FormControlLabel, IconButton } from "@mui/material"
import { GridLogicOperator } from "@mui/x-data-grid"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import ErrorIcon from "@mui/icons-material/Error"
import HistoryEduIcon from "@mui/icons-material/HistoryEdu"
import CancelIcon from "@mui/icons-material/Cancel"
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded"
import ReplayIcon from "@mui/icons-material/Replay"
import clsx from "clsx"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"
import "react-toastify/dist/ReactToastify.css"

// ** Custom
import AppContext from "../AppContext"
import { CustomStripedGrid } from "./CustomStripedGrid"
import LoadingBackdrop, {
  getProgramName,
  applySignatureCaps,
  shortenRequestType,
  getPathname,
  permissionCheck,
  isRequestLocal,
  getTimezoneFormattedDate,
  SetTimeAndOffset,
  createEmailBody,
} from "../utils"
import { NextSignatureModal } from "./NextSignatureModal"
import { ConfirmationModal } from "./ConfirmationModal"

const WarehouseRequestTabView = ({
  title,
  selectedGlobalProgramId,
  allWRTData,
  handleEditFormSubmit,
  handleSkipHazmat,
  handleReopenOrCancelWRT,
  leftMenuDrawerOpen,
}) => {
  let context = useContext(AppContext)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const [loaded, setLoaded] = useState(false)
  const [rows, setRows] = useState("")
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [WRTToConfirm, setWRTToConfirm] = useState("")
  const [WRTToCancel, setWRTToCancel] = useState("")
  const [statuses, setStatuses] = useState({
    PENDING: false,
    COMPLETE: false,
    CANCELLED: false,
  })
  const [filterModel, setFilterModel] = useState()
  let selectedProgramId = selectedGlobalProgramId
  let pathname = getPathname(window.location.pathname)

  const signaturesNeeded = {
    Turn: ["partsSignature", "tiSignature", "qaSignature", "hazmatSignature", "turnInSignature"],
    Movement: ["partsSignature", "tiSignature", "leadSignature", "hazmatSignature"],
    Pickup: ["partsSignature", "tiSignature", "leadSignature", "hazmatSignature"],
    Pull: ["partsSignature", "tiSignature", "qaSignature", "hazmatSignature", "transportationSignature"],
  }

  const determineNextSignatureNeeded = async (currentWRT) => {
    let sigObjKeys = Object.keys(signaturesNeeded)
    let nextSignature = sigObjKeys.reduce((acc, requestKeyType) => {
      if (currentWRT.requestType.includes(requestKeyType)) {
        let sigNeeded = signaturesNeeded[requestKeyType].find((signatureNeeded) => !currentWRT[signatureNeeded])

        let updatedSigNeeded = sigNeeded === undefined ? "N/A" : sigNeeded
        acc = updatedSigNeeded
        return sigNeeded === undefined ? "N/A" : sigNeeded
      }
      return acc
    }, "")

    return nextSignature
  }

  const determineIsHazmatRequired = async (currentWRTId) => {
    let WRTobj = await apiCalls.getById(currentWRTId, "WarehouseRequestTransactions", "?%24expand=requestItems")
    let WRTReqItems = await WRTobj.data.requestItems
    let isHazmatReq = [...WRTReqItems].some((item) => item.hazardousMaterielIndicatorCode === "Y")
    return isHazmatReq
  }

  useEffect(() => {
    if (allWRTData) {
      /*
       * Determines the WRTs to be pulled depending on if a parent vs a sub program is selected.
       * If a parent program is selected, all of its sub programs' WRTs should be pulled and shown.
       */
      let filteredWRTs = [...allWRTData].filter((wrt) =>
        wrt.parentProgramId && wrt.parentProgramId === selectedGlobalProgramId
          ? wrt.parentProgramId === selectedGlobalProgramId
          : wrt.programId === selectedGlobalProgramId
      )
      const updateWRTsWithSignature = async () => {
        let updatedWRTs = await Promise.all(
          [...filteredWRTs].map(async (wrt) => {
            let nextSigNeeded = await determineNextSignatureNeeded(wrt)
            let isHazmatReq = await determineIsHazmatRequired(wrt.id)
            let isReqTypeLocal = isRequestLocal(wrt.requestType)
            let updatedWRT = {
              ...wrt,
              nextSignature: nextSigNeeded,
              isHazmatRequired: isHazmatReq,
              isLocal: isReqTypeLocal,
            }

            return updatedWRT
          })
        )
        setRows(updatedWRTs)
        setLoaded(true)
        return updatedWRTs
      }
      updateWRTsWithSignature()
    }
  }, [allWRTData, selectedGlobalProgramId])

  useEffect(() => {
    let items = Object.entries(statuses)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        return {
          id: `${key}:${value}`,
          field: "status",
          operator: "equals",
          value: key,
        }
      })
    setFilterModel({ items, logicOperator: GridLogicOperator.Or })
  }, [statuses])

  // THOUGHT: def an opportunity to refactor, DRY up code and make modals dynamic when have time/space/bandwidth in future

  const toggleSignatureModal = () => {
    setIsSignatureModalOpen(true)
  }

  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(true)
  }
  ///

  const updatedOriginalInventoryRecords = async (finalRequestItems) => {
    return await Promise.all(
      [...finalRequestItems].map(async (finalRequestItem) => {
        let OGInvRec = await apiCalls.getById(finalRequestItem.inventoryRecordId, "InventoryRecords", "")
        let OGInvRecObj = OGInvRec.data
        let updatedQty = OGInvRecObj.quantity - finalRequestItem.quantity
        let updatedTotalCost = finalRequestItem.unitPrice * updatedQty
        let isIssued = !updatedQty ? true : false

        return {
          ...OGInvRecObj,
          issued: isIssued,
          quantity: updatedQty,
          totalCost: updatedTotalCost,
        }
      })
    )
  }

  const handleInventoryRecordUpdates = async (id, record) => {
    try {
      await apiCalls.putRecord(record, "InventoryRecords", id)
    } catch (error) {
      console.error(error)
    }
  }

  const getWRTRequestItems = async (id) => {
    let WRTobj = await apiCalls.getById(id, "WarehouseRequestTransactions", "?%24expand=requestItems")
    let WRTReqItems = await WRTobj.data.requestItems
    return WRTReqItems
  }

  const handleConfirmSignature = async (response) => {
    if (response) {
      let requestItemsFromGetById = await getWRTRequestItems(WRTToConfirm.id)
      let dateField = await determineDateFieldToUpdate(`${WRTToConfirm.nextSignature}`)

      let updatedWRT = {
        ...WRTToConfirm,
        [`${WRTToConfirm.nextSignature}`]: context.username,
        requestItems: requestItemsFromGetById,
        [dateField]: SetTimeAndOffset(new Date().toISOString()),
      }

      await handleEditFormSubmit(WRTToConfirm.id, updatedWRT, pathname)
      await determineNextSignatureNeeded(updatedWRT).then(async (nextSignature) => {
        // Don't send the notifications if nextSignature is N/A.
        if (nextSignature !== "N/A") {
          let body = createEmailBody("warehouse", updatedWRT.programName, updatedWRT.documentNumber, "PENDING", nextSignature, false)
          await apiCalls.sendEmailNotifications(body)
        }
      })
    }
    setWRTToConfirm("")
    setIsSignatureModalOpen(false)
  }

  const determineDateFieldToUpdate = async (signature) => {
    return signature.includes("parts")
      ? "partsDate"
      : signature.includes("ti")
      ? "tiDate"
      : signature === "qaSignature"
      ? "qaDate"
      : signature.includes("lead")
      ? "leadDate"
      : signature.includes("hazmat")
      ? "hazmatDate"
      : signature === "transportationSignature"
      ? "transportationDate"
      : signature.includes("turnIn")
      ? "turnInDate"
      : null
  }

  const handleConfirmationChange = (e) => {
    const { name, value } = e.target
    setWRTToConfirm((prevWRT) => {
      return {
        ...prevWRT,
        [name]: value,
      }
    })
  }

  const handleCancelChange = (e) => {
    const { name, value } = e.target
    setWRTToCancel((prevWRT) => {
      return {
        ...prevWRT,
        [name]: value,
      }
    })
  }

  const handleCheckChange = (e) => {
    setStatuses((prevStatuses) => {
      return {
        ...prevStatuses,
        [e.target.name]: e.target.checked,
      }
    })
  }

  const handleCaptureCancelledWRT = async (response, reasoning) => {
    if (response) {
      let cancelledRequestItems = await getWRTRequestItems(WRTToCancel.id)
      let cancelWRTpatchChanges = [
        { path: "status", op: "replace", value: "CANCELLED" },
        { path: "cancelReason", op: "replace", value: reasoning },
        { path: "cancelSignature", op: "replace", value: context.username },
        { path: "cancelDate", op: "replace", value: SetTimeAndOffset(new Date().toISOString()) },
        { path: "requestItems", op: "replace", value: cancelledRequestItems },
      ]

      await handleReopenOrCancelWRT(WRTToCancel.id, "WarehouseRequestTransactions", cancelWRTpatchChanges, "cancel", WRTToCancel.programName)
    }
    setIsConfirmationModalOpen(false)
    setWRTToCancel("")
  }

  const handleUpdateWRTToConfirm = async (response, reasoning) => {
    if (response) {
      let finalRequestItems = await getWRTRequestItems(WRTToConfirm.id)
      let OGrecordsUpdated = await updatedOriginalInventoryRecords(finalRequestItems)

      OGrecordsUpdated.forEach(async (record) => await handleInventoryRecordUpdates(record.id, record))

      const completeWRT = {
        ...WRTToConfirm,
        status: "COMPLETE",
        notes: reasoning,
        requestItems: finalRequestItems,
        finalDepartureDate: SetTimeAndOffset(new Date().toISOString()),
        finalSignature: context.username,
      }

      await handleEditFormSubmit(WRTToConfirm.id, completeWRT, pathname)
    }
    setIsConfirmationModalOpen(false)
    setWRTToConfirm("")
  }

  const handleReopenWRT = async (row) => {
    const reopenWRT = {
      ...row,
      reopenWRTpatchChanges: [
        { path: "status", op: "replace", value: "PENDING" },
        { path: "finalDepartureDate", op: "replace", value: null },
        { path: "finalSignature", op: "replace", value: "" },
      ],
    }

    await handleReopenOrCancelWRT(row.id, "WarehouseRequestTransactions", reopenWRT, "reopen", row.programName)
  }

  const handleSkipClicked = async (id, signatureField, signatureValue) => {
    await handleSkipHazmat(id, signatureField, signatureValue)
  }

  // DATAGRID related:
  const isNextSignatureDisabled = (params) => {
    let isPartsSignature = params.row.nextSignature === "partsSignature"
    let isTISignature = params.row.nextSignature === "tiSignature"
    let isQASignature = params.row.nextSignature === "qaSignature"
    let isLeadSignature = params.row.nextSignature === "leadSignature"
    let isHazmatSignature = params.row.nextSignature === "hazmatSignature"
    let isTransportationSignature = params.row.nextSignature === "transportationSignature"
    let isTurnInSignature = params.row.nextSignature === "turnInSignature"
    let isNotPending = params.row.status !== "PENDING"

    if (isNotPending) {
      return true
    }
    if (
      (isPartsSignature && context.isICP) ||
      (isTISignature && context.isTI) ||
      (isQASignature && context.isQA) ||
      (isLeadSignature && context.isTL) ||
      (isHazmatSignature && context.isHazmat) ||
      (isTransportationSignature && context.isShipping) ||
      (isTurnInSignature && context.isTurnIn)
    ) {
      return false
    } else {
      return true
    }
  }

  const determineIconToDisplay = (params) => {
    let isPending = params.row.status === "PENDING"
    let isDisabled = params.row.status === "COMPLETE" || params.row.status === "CANCELLED" ? true : false
    let isDisabledIfCancelled = params.row.status === "CANCELLED" ? true : false
    let noNextSig = params.row.nextSignature === "N/A"
    let isLocal = params.row.requestType === "Movement Request - Local Delivery" || params.row.requestType === "Pickup Request - Local Pickup only"
    let isShipping =
      params.row.requestType === "Turn-in Request - Assets requested to be Turned-in/DRMO" ||
      params.row.requestType === "Pull Request - Assets requested to be shipped"
    let isPR = params.row.requestType === "Pull Request - Assets requested to be shipped"
    let needsDimensions =
      (isPR && isPending && !params.row.dimensionsHeight) ||
      (isPR && isPending && !params.row.dimensionsLength) ||
      (isPR && isPending && !params.row.dimensionsWidth) ||
      (isPR && isPending && !params.row.weight)

    if (noNextSig && isPR && needsDimensions) {
      return (
        <Tooltip
          title="Dimensions Required On This Request"
          placement="left"
          arrow
        >
          <span>
            <Button
              className="error-icon-wrapper"
              disabled={isDisabledIfCancelled}
            >
              <ErrorIcon />
            </Button>
          </span>
        </Tooltip>
      )
    }
    if (noNextSig && isShipping) {
      return (
        <Tooltip
          title="Confirm Shipping"
          placement="left"
          arrow
        >
          <span>
            <Button
              className="confirm-icon"
              disabled={isDisabled}
              onClick={() => {
                setWRTToConfirm(params.row)
                toggleConfirmationModal()
              }}
            >
              <LocalShippingIcon />
            </Button>
          </span>
        </Tooltip>
      )
    } else if (noNextSig && isLocal) {
      return (
        <Tooltip
          title="Confirm Local"
          placement="left"
          arrow
        >
          <span>
            <Button
              className="confirm-icon"
              disabled={isDisabled}
              onClick={() => {
                setWRTToConfirm(params.row)
                toggleConfirmationModal()
              }}
            >
              <HowToRegIcon />
            </Button>
          </span>
        </Tooltip>
      )
    } else {
      return null
    }
  }

  let sigListForType = (currentWRT) => {
    let sigObjKeys = Object.keys(signaturesNeeded)
    let sigList = sigObjKeys.reduce((acc, requestKeyType) => {
      if (currentWRT.requestType?.includes(requestKeyType)) {
        acc = signaturesNeeded[requestKeyType]
      }
      return acc
    }, [])
    return sigList
  }

  const getReqDeliveryClassName = (params) => {
    let today = new Date()
    let stringToday = today.toISOString()
    let currDate = new Date(getTimezoneFormattedDate(stringToday, false))
    let reqDelivDate = new Date(getTimezoneFormattedDate(params.value, false))

    let daysBetweenDates = Math.floor((reqDelivDate.getTime() - currDate.getTime()) / (1000 * 60 * 60 * 24))

    if (params.row.status === "COMPLETE" || params.row.status === "CANCELLED") {
      return ""
    }
    return clsx("req-delivery-date", {
      // Green
      fivePlus: daysBetweenDates >= 5,
      // Yellow
      threeToFour: daysBetweenDates === 3 || daysBetweenDates === 4,
      // Red
      oneToTwo: daysBetweenDates <= 2,
    })
  }

  const wrtColumns = [
    {
      field: "documentNumber",
      headerName: "Document Number",
      minWidth: 175,
      flex: 1,
      renderCell: (params) => {
        let isPending = params.row.status === "PENDING"
        let statusLC = params.row.status.toLowerCase()
        let sigList = sigListForType(params.row)

        return (
          <div className="editable">
            <Link
              to={`/warehouse_request/${statusLC}_request/${params.id}`}
              state={{
                isPending: isPending,
                reqStatus: statusLC,
                signaturesList: sigList,
              }}
            >
              {params.value}
            </Link>
          </div>
        )
      },
    },
    {
      field: "requestDate",
      headerName: "Request Date",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return getTimezoneFormattedDate(params?.value, false)
      },
    },
    {
      field: "requiredDeliveryDate",
      headerName: "Required Delivery Date",
      minWidth: 180,
      flex: 1.1,
      valueFormatter: (params) => {
        return getTimezoneFormattedDate(params?.value, false)
      },
      cellClassName: (params) => {
        return getReqDeliveryClassName(params)
      },
    },
    {
      field: "requestedBy",
      headerName: "Requested By",
      minWidth: 150,
      flex: 1,
    },
    { field: "status", headerName: "Status", minWidth: 100, flex: 1 },
    {
      field: "requestType",
      headerName: "Request Type",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return <>{shortenRequestType(params.value)}</>
        }
      },
    },
    {
      field: "nextSignature",
      headerName: "Next Signature",
      minWidth: 190,
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          let conditionToShowSkip = params.row.nextSignature === "hazmatSignature" && !params.row.isHazmatRequired
          let conditionToDisplayNA = params.row.nextSignature === "N/A"

          return (
            <div className="hazmat-wrapper">
              {!conditionToDisplayNA && (
                <Button
                  className="signature-needed"
                  disabled={isNextSignatureDisabled(params)}
                  startIcon={<HistoryEduIcon />}
                  onClick={() => {
                    setWRTToConfirm(params.row)
                    toggleSignatureModal()
                  }}
                >
                  {conditionToShowSkip ? applySignatureCaps(params.value).substring(0, 6) : applySignatureCaps(params.value)}
                </Button>
              )}
              {conditionToShowSkip && (
                <Button
                  disabled={isNextSignatureDisabled(params)}
                  className="signature-needed"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    handleSkipClicked(params.id, "hazmatSignature", "skipped")
                  }}
                >
                  Skip
                </Button>
              )}
              {conditionToDisplayNA && (
                <div className="NA-wrapper">
                  <Button
                    disabled={true}
                    className="signature-needed-NA"
                  >
                    {params.value.toUpperCase()}
                  </Button>
                </div>
              )}
            </div>
          )
        }
      },
    },
    {
      field: "confirmation",
      headerName: "",
      minWidth: 100,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return determineIconToDisplay(params)
      },
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return getTimezoneFormattedDate(params?.value, true)
      },
    },
    {
      field: "reopenOrCancelRequest",
      headerName: "",
      minWidth: 50,
      align: "center",
      renderCell: (params) => {
        let isPending = params.row.status === "PENDING"
        let isComplete = params.row.status === "COMPLETE"
        let reopenOrCancelColumn = isPending ? (
          <Tooltip
            title="Cancel Request"
            placement="left"
            arrow
          >
            <span>
              <IconButton
                color="error"
                disabled={!isPending}
                onClick={() => {
                  setWRTToCancel(params.row)
                  toggleConfirmationModal()
                }}
              >
                <DisabledByDefaultRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : isComplete ? (
          <Tooltip
            title="Reopen Request"
            placement="left"
            arrow
          >
            <span>
              <IconButton
                color="primary"
                disabled={!isComplete}
                onClick={async () => {
                  await handleReopenWRT(params.row)
                }}
              >
                <ReplayIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : null
        return reopenOrCancelColumn
      },
    },
  ]

  const customGetRowClassName = (params) => {
    let updatedRowClass
    let noNextSig = params.row.nextSignature === "N/A"
    let isPR = params.row.requestType === "Pull Request - Assets requested to be shipped"
    let isPending = params.row.status === "PENDING"

    let needsDimensions =
      (isPR && !params.row.dimensionsHeight && isPending) ||
      (isPR && !params.row.dimensionsLength && isPending) ||
      (isPR && !params.row.dimensionsWidth && isPending) ||
      (isPR && !params.row.weight && isPending)
    let rowClass = params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
    let dimensions = "dimensions"
    let pending = "pending"
    if (noNextSig && isPR && needsDimensions) {
      updatedRowClass = `${rowClass} ${dimensions}`
      return updatedRowClass
    } else if (isPending) {
      updatedRowClass = `${rowClass} ${pending}`
      return updatedRowClass
    } else {
      return rowClass
    }
  }

  let isCancelReq = WRTToCancel ? true : WRTToConfirm ? false : null
  let WRTtoReference = WRTToCancel ? WRTToCancel : WRTToConfirm ? WRTToConfirm : null
  const { PENDING, COMPLETE, CANCELLED } = statuses

  if (loaded) {
    return (
      <>
        {isSignatureModalOpen && (
          <NextSignatureModal
            shouldOpen={true}
            handleConfirmSignature={handleConfirmSignature}
          />
        )}
        {isConfirmationModalOpen && (
          <ConfirmationModal
            isCancelled={isCancelReq}
            shouldOpen={true}
            handleUpdateWRT={WRTToConfirm ? handleUpdateWRTToConfirm : WRTToCancel ? handleCaptureCancelledWRT : null}
            referenceWRT={WRTtoReference}
            handleChange={WRTToConfirm ? handleConfirmationChange : WRTToCancel ? handleCancelChange : null}
          />
        )}
        <Box>
          <div style={{ marginBottom: "15px" }}>
            <FormControl component="fieldset">
              <FormGroup
                aria-label="position"
                row
              >
                <FormControlLabel
                  value="PENDING"
                  control={
                    <Checkbox
                      checked={PENDING}
                      onChange={handleCheckChange}
                      name="PENDING"
                    />
                  }
                  label="Pending"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="COMPLETE"
                  control={
                    <Checkbox
                      checked={COMPLETE}
                      onChange={handleCheckChange}
                      name="COMPLETE"
                    />
                  }
                  label="Complete"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="CANCELLED"
                  control={
                    <Checkbox
                      checked={CANCELLED}
                      onChange={handleCheckChange}
                      name="CANCELLED"
                    />
                  }
                  label="Cancelled"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </div>
          <CustomStripedGrid
            data={rows}
            columns={wrtColumns}
            loading={!rows}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            title={`${getProgramName(selectedProgramId, context.allProgramsData)} ${title}`}
            rowCustomization={customGetRowClassName}
            disableColumnSelector={true}
            disableColumnFilter={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  confirmation: notPermitted ? false : true,
                  reopenOrCancelRequest: notPermitted ? false : true,
                },
              },
              sorting: {
                sortModel: [{ field: "requiredDeliveryDate", sort: "desc" }],
              },
            }}
          />
        </Box>
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default WarehouseRequestTabView
