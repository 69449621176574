import { useState } from "react"

// ** MUI
import { Box, Button, Divider, FormControl, Grid, IconButton, Modal, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { style } from "../utils"

export const ConfirmationModal = ({ isCancelled, shouldOpen, handleChange, handleUpdateWRT, referenceWRT }) => {
  const [open, setOpen] = useState(shouldOpen)
  const [reasoning, setReasoning] = useState("") // For cancelled reasoning and notes.

  const handleValidTAC = (value) => {
    // NOTE: TAC must be four characters comprised of only uppercase letters and digits.
    let pattern = new RegExp(/^$|(\b[A-Z0-9]{4,4}\b)$/)
    return pattern.test(value) || value === null ? false : true
  }

  const handleClose = () => setOpen(false)
  let determineHeader = referenceWRT.isLocal ? "Local Delivery / Pickup" : "Shipment Information"
  let determineButtonDisabled =
    (!referenceWRT.isLocal && !referenceWRT.trackingNumber) ||
    (!referenceWRT.isLocal && !referenceWRT.carrierInformation) ||
    (!referenceWRT.isLocal && !referenceWRT.transportationAccountCode) ||
    (!referenceWRT.isLocal && handleValidTAC(referenceWRT.transportationAccountCode))

  const handleConfirmClick = (response, reasoning) => {
    handleUpdateWRT(response, reasoning)
    handleClose()
  }

  const addBulletPoint = (e) => {
    if (e.key === "Enter") {
      setReasoning(reasoning + "• ")
    }
  }

  const showBottomText = () => {
    return (
      <>
        <span>Accepting this request will update the status to </span>
        <span className="highlight-green">COMPLETE</span>
        <span>.</span>
      </>
    )
  }

  const showCancelledMessage = () => {
    return (
      <>
        <span>Are you sure you'd like to cancel request </span>
        <span className="highlight-blue">{referenceWRT.documentNumber}</span>
        <span>? If so, please provide your reason(s) for cancelling below:</span>
      </>
    )
  }

  const showMainMessage = () => {
    return !referenceWRT.isLocal ? (
      <span>
        Please provide the shipping information below for request <span className="highlight-blue">{referenceWRT.documentNumber}</span>:
      </span>
    ) : (
      <span>
        Please provide any relevant notes below for request <span className="highlight-blue">{referenceWRT.documentNumber}</span>:
      </span>
    )
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleConfirmClick(false, "")}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={12}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">{isCancelled ? "Cancel Warehouse Request" : determineHeader}</Divider>
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={12}
            sx={{ textAlign: "center" }}
          >
            {isCancelled ? showCancelledMessage() : showMainMessage()}
          </Grid>

          {/* CANCEL REASONING */}
          {isCancelled && (
            <Grid
              item
              xs={12}
            >
              <FormControl sx={{ minWidth: "100%", minHeight: "50%" }}>
                <TextField
                  name="cancelReason"
                  id="cancelReason"
                  type="text"
                  value={reasoning}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  multiline={true}
                  rows={8}
                  onChange={(e) => setReasoning(e.target.value)}
                  onKeyUp={addBulletPoint}
                  onFocus={() => setReasoning("• ")} // Add so first line is bulleted.
                />
              </FormControl>
            </Grid>
          )}

          {/* SHIPMENT/LOCAL DELIVERY */}
          {isCancelled ? null : !referenceWRT.isLocal ? (
            <>
              <Grid
                item
                xs={12}
              >
                <TextField
                  sx={{ width: "100%", mb: 1, mt: 1 }}
                  name="trackingNumber"
                  id="trackingNumber"
                  label="Tracking Number"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  value={referenceWRT.trackingNumber}
                  required
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  sx={{ width: "100%", mb: 1, mt: 1 }}
                  name="carrierInformation"
                  id="carrierInformation"
                  label="Carrier Information"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  required
                  value={referenceWRT.carrierInformation}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  sx={{ width: "100%", mb: 1, mt: 1 }}
                  name="transportationAccountCode"
                  id="transportationAccountCode"
                  label="Transportation Account Code"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  required
                  value={referenceWRT.transportationAccountCode}
                  onChange={handleChange}
                  error={handleValidTAC(referenceWRT.transportationAccountCode)}
                  helperText={
                    handleValidTAC(referenceWRT.transportationAccountCode)
                      ? "* TAC must be four characters with only uppercase letters and digits."
                      : null
                  }
                />
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
            >
              <TextField
                sx={{ width: "100%", mt: 1 }}
                name="notes"
                id="notes"
                label="Notes"
                InputLabelProps={{ shrink: true }}
                type="text"
                value={reasoning}
                size="small"
                multiline={true}
                rows={8}
                onChange={(e) => setReasoning(e.target.value)}
                onKeyUp={addBulletPoint}
                onFocus={() => setReasoning("• ")}
              />
            </Grid>
          )}

          {/* BOTTOM TEXT */}
          <Grid
            item
            xs={12}
            sx={{ textAlign: "center" }}
          >
            {!isCancelled ? showBottomText() : null}
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={8.5}
          />
          <Grid
            item
            xs={3.5}
          >
            <Button
              variant="contained"
              color={isCancelled ? "error" : "primary"}
              disabled={isCancelled ? !reasoning.length : determineButtonDisabled}
              onClick={() => handleConfirmClick(true, reasoning)}
            >
              {isCancelled ? "Confirm" : "Accept"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
