import * as React from "react"
import { useState } from "react"

// ** MUI
import { Box, Button, Divider, FormControl, Grid, IconButton, Modal, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { style } from "../utils"

// NOTE: This component handles capturing reasoning for record changes and file deletion confirmation.
export const InventoryChangeReasonModal = ({ shouldOpen, handleConfirmClose, title, type, text }) => {
  const [open, setOpen] = useState(shouldOpen)
  const [reasoning, setReasoning] = useState("")
  const isPostReason = type === "add"
  const isDeleteFile = type === "deleteFile"

  const addBulletPoint = (e) => {
    if (e.key === "Enter") {
      setReasoning(reasoning + "• ")
    }
  }

  const showModalText = () => {
    return (
      <>
        <span>Are you sure you want to delete </span>
        <span className="highlight-blue">{text}</span>
        <span>?</span>
      </>
    )
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleConfirmClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={11}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "15px !important" }}
          >
            {isDeleteFile ? (
              <Divider className={"divider-title"}>{title}</Divider>
            ) : (
              <Divider
                sx={{ fontFamily: "Merriweather" }}
                textAlign="left"
              >
                {title}
              </Divider>
            )}
          </Grid>

          {/* TEXT/INPUT */}
          {isDeleteFile ? (
            <Grid
              item
              xs={11}
              sx={{ textAlign: "center" }}
            >
              {showModalText()}
            </Grid>
          ) : (
            <Grid
              item
              xs={11}
            >
              <FormControl sx={{ minWidth: "100%" }}>
                <TextField
                  name="reason"
                  id="reason"
                  type="text"
                  value={isPostReason ? reasoning : text}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  multiline={true}
                  rows={8}
                  disabled={!isPostReason}
                  onChange={(e) => setReasoning(e.target.value)}
                  onKeyUp={addBulletPoint}
                  onFocus={() => setReasoning("• ")} // Add so first line is bulleted.
                />
              </FormControl>
            </Grid>
          )}

          {/* BUTTONS */}
          <Grid
            item
            xs={8}
          ></Grid>
          {isPostReason && (
            <Grid
              item
              xs={2}
              sx={{ paddingTop: "20px !important" }}
            >
              <Button
                variant="contained"
                onClick={() => handleConfirmClose(reasoning)}
                disabled={reasoning.length == 0}
              >
                Submit
              </Button>
            </Grid>
          )}
          {isDeleteFile && (
            <Grid
              item
              xs={2}
              sx={{ paddingTop: "20px !important" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => handleConfirmClose(true)}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  )
}
