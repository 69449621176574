import { useContext, useState, useRef } from "react"

// ** MUI
import { Box, Button, Divider, Grid, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import CustomProgressDialog from "./CustomProgressDialog"
import { notify, style } from "../utils"

export const CSVUploadModal = ({ shouldOpen, handleClose, selectedGlobalProgramId, handleInventoryUpdate }) => {
  let context = useContext(AppContext)
  const [open, setOpen] = useState(shouldOpen)
  const [isLoading, setIsLoading] = useState(false)
  const csvFile = useRef(null)

  const handleFileUpload = async (file) => {
    if (file?.type === "text/csv") {
      setIsLoading(true)
      csvFile.current = file
      const selectedFileName = csvFile.current.name
      const fileReader = new FileReader()
      fileReader.onload = async () => {
        const formData = new FormData()
        formData.append("FormFile", new Blob([csvFile.current], { type: "text/csv" }), selectedFileName)
        try {
          // Add the temp file and start Hangfire task of updating data.
          await apiCalls.actionOnInventoryRecords("TempFile", formData).then(async (response) => {
            if (response.status === 200) {
              let body = {
                fileName: selectedFileName,
                programId: selectedGlobalProgramId,
                userEmail: context.userEmail,
              }

              await apiCalls.updateRecordsWithHangfire(body)
            }
          })

          setIsLoading(false)
          notify("success", "An email will be sent once all the items' Inventory Date have been updated.")
          handleClose(false)
          handleInventoryUpdate()
        } catch (error) {
          console.error(error)
          setIsLoading(false)
          notify("error", "There was a problem updating the inventory. Please try again.")
          csvFile.current = null
        }
      }
      fileReader.readAsText(csvFile.current)
    } else {
      setIsLoading(false)
      notify("error", `File type not supported. Please upload a CSV file.`)
      csvFile.current = null
    }
  }

  return (
    <div>
      {isLoading && (
        <CustomProgressDialog
          open={isLoading}
          progress={null}
          title="Updating..."
        />
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* CLOSE ICON */}
          <Grid
            container
            spacing={2}
          >
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            container
            spacing={2}
            columns={11}
          >
            {/* TOP DIVIDER */}
            <Grid
              item
              xs={11}
              sx={{ paddingTop: "15px !important" }}
            >
              <Divider className="divider-title">TIS File Import</Divider>
            </Grid>

            {/* TEXT */}
            <Grid
              item
              xs={11}
              sx={{ textAlign: "center" }}
            >
              Upload a <b>CSV</b> file to update the <b>Inventory Date</b> field for the items listed in an exported file from the TIS. The file
              should have the following columns: <i>Property Number, RFID, Serial Number</i> and <i>Inventory Date</i>.
            </Grid>

            {/* BUTTON */}
            <Grid
              item
              xs={8}
            />
            <Grid
              item
              xs={3}
            >
              <Button
                variant="contained"
                component="label"
              >
                UPLOAD
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={(e) => {
                    handleFileUpload(e.target.files[0])
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
