import { useEffect, useState, useContext } from "react"

// ** Toastify
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { Box, Divider, Grid, IconButton, Modal } from "@mui/material"
import { DataGridPremium } from "@mui/x-data-grid-premium"
import CloseIcon from "@mui/icons-material/Close"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"
import "./styles/ModalStyles.css"

// ** Custom
import { notify, permissionCheck, replaceSpecialCharacters, style } from "../utils"

const RequestItemsGrid = ({
  requestForm,
  handleDisableSubmitButton,
  isPending,
  requestItems,
  handleUpdateRequestItems,
  partsSignature,
  partsDate,
  handleDeleteItemEditForm,
}) => {
  const context = useContext(AppContext)
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const [open, setOpen] = useState(false)
  const [modifiedRow, setModifiedRow] = useState()

  useEffect(() => {
    const addCurrentBalanceToItems = async () => {
      setIsLoading(true)
      let revisedRequestItems = await Promise.all(
        [...requestItems].map(async (item) => {
          let referenceRecordResponse = await fetchReferenceRecord(item.inventoryRecordId)
          let revisedItem = {
            ...item,
            currentBalance: referenceRecordResponse.currentBalance,
            originalRequestItemQty: item.quantity,
          }
          return revisedItem
        })
      )
      setRows(revisedRequestItems)
      setIsLoading(false)
      return revisedRequestItems
    }
    addCurrentBalanceToItems()
  }, [])

  const fetchReferenceRecord = async (itemInventoryId) => {
    let referenceRecordResponse = await apiCalls.filterRecordsById(itemInventoryId)
    let referenceRecord = await referenceRecordResponse.data.value[0]
    return referenceRecord
  }

  const isEditable = isPending && context.isICP ? true : false

  const handleProcessRowUpdate = async (updatedRow, originalRow) => {
    let isNotSerial = !updatedRow.serialNumber || updatedRow.serialNumber === "NA"
    let ogQty = originalRow.originalRequestItemQty
    let newQty = updatedRow.quantity
    let qtyDiff = newQty > ogQty ? newQty - ogQty : ogQty - newQty
    let isQtyLessThanBalance =
      (updatedRow.quantity >= originalRow.originalRequestItemQty && qtyDiff <= originalRow.currentBalance) ||
      updatedRow.quantity <= originalRow.originalRequestItemQty

    if ((isNotSerial && isQtyLessThanBalance) || !isNotSerial) {
      // Check if serial number exists if modified in the table.
      if (updatedRow.serialNumber !== originalRow.serialNumber) {
        // Check if the new serial number entered already exists in the request.
        let existingSerials = requestItems.map((f) => {
          return { nationalStockNumber: f.nationalStockNumber, partNumber: f.partNumber, serialNumber: f.serialNumber }
        })
        if (checkIfInRequestAlready(existingSerials, updatedRow)) {
          let newSerial = updatedRow.serialNumber
          setModifiedRow({ serialNumber: newSerial, modalText: "exists" })
          setOpen(true)

          // Set row back to the original serial number if the serial already exists in the request.
          updatedRow.serialNumber = originalRow.serialNumber
        } else {
          let foundRecord = await checkIfSerializedRecordExists(updatedRow)
          if (foundRecord !== null) {
            updatedRow.conditionCode = foundRecord.conditionCode
            updatedRow.hazardousMaterielIndicatorCode = foundRecord.hazardousMaterielIndicatorCode
            updatedRow.inventoryRecordId = foundRecord.id
            updatedRow.location = foundRecord.location
            updatedRow.nomenclature = foundRecord.nomenclature
            updatedRow.propertyNumber = foundRecord.propertyNumber
            updatedRow.serialNumber = foundRecord.serialNumber
            updatedRow.unitPrice = foundRecord.unitPrice
          } else {
            setModifiedRow({
              serialNumber: updatedRow.serialNumber,
              nationalStockNumber: updatedRow.nationalStockNumber,
              partNumber: updatedRow.partNumber,
              modalText: "doesNotExist",
            })
            setOpen(true)
            updatedRow.serialNumber = originalRow.serialNumber
          }
        }
      }

      updatedRow.totalCost = updatedRow.quantity * updatedRow.unitPrice
      const updatedRows = [...rows]
      const rowIndex = rows.findIndex((row) => row.id === updatedRow.id)
      updatedRows[rowIndex] = updatedRow
      setRows(updatedRows)
      handleUpdateRequestItems(updatedRows)
      return updatedRow
    } else if (isNotSerial && !isQtyLessThanBalance) {
      notify(
        "error",
        `The Qty entered exceeds the amount currently available. The maximum available amount is: ${
          originalRow.originalRequestItemQty + originalRow.currentBalance
        }.`
      )
      return originalRow
    }
  }

  const checkIfSerializedRecordExists = async (row) => {
    // NOTE: Encode S/N and P/N values if they have specific special characters.
    let newSerialNumber = row.serialNumber
    let newPartNumber = row.partNumber
    let specialCharPattern = new RegExp(/[ `#%&+\/?]/)
    if (specialCharPattern.test(row.serialNumber) || specialCharPattern.test(row.partNumber)) {
      newSerialNumber = replaceSpecialCharacters(newSerialNumber)
      newPartNumber = replaceSpecialCharacters(newPartNumber)
    }

    let recordFound = await apiCalls.getRecords(
      `InventoryRecords?%24filter=ManagementRecordArmyProgramId%20eq%20${requestForm.programId}%20and%20serialNumber%20eq%20%27${newSerialNumber}%27%20and%20partNumber%20eq%20%27${newPartNumber}%27%20and%20nationalStockNumber%20eq%20%27${row.nationalStockNumber}%27`
    )

    if (recordFound.data.value.length === 0 || recordFound.data.value[0].quantity === 0) return null
    else {
      // If the record it not depleted and exists, check if the record found already exists in another request/transfer.
      let checkRequestedItem = await apiCalls.getRecords(`WarehouseRequestItems?%24filter=InventoryRecordId%20eq%20${recordFound.data.value[0].id}`)
      let checkTransferredItem = await apiCalls.getRecords(`TransferItems?%24filter=InventoryRecordId%20eq%20${recordFound.data.value[0].id}`)

      if (checkRequestedItem.data.value[0] || checkTransferredItem.data.value[0]) return null
      else return recordFound.data.value[0]
    }
  }

  const checkIfInRequestAlready = (existingSerials, row) => {
    if (
      existingSerials.some(
        (f) => f.nationalStockNumber === row.nationalStockNumber && f.partNumber === row.partNumber && f.serialNumber === row.serialNumber
      )
    )
      return true
    else return false
  }

  const handleRemoveRequestItem = async (reqItemId) => {
    let responseInEditForm = await handleDeleteItemEditForm(reqItemId)
    if (responseInEditForm.status === 204) {
      let revisedReqItems = rows.filter((requestItem) => requestItem.id !== reqItemId)
      setRows(revisedReqItems)
      handleUpdateRequestItems(revisedReqItems)
    }
  }

  const showModalText = (row) => {
    return row.modalText === "exists" ? (
      <>
        <span>A serialized item with the serial number </span>
        <span className="highlight-red">({row.serialNumber})</span>
        <span> and matching NSN and P/N already exists in the warehouse request.</span>
      </>
    ) : (
      <>
        <span>No serialized item was found with the serial number: </span>
        <span className="highlight-blue">{row.serialNumber}</span>
        <span>, NSN: </span>
        <span className="highlight-blue">{!row.nationalStockNumber ? "[None]" : row.nationalStockNumber}</span>
        <span>, and P/N: </span>
        <span className="highlight-blue">{row.partNumber}</span>
        <span>, the serialized item already exists in another process/request, or the serialized item has a quantity of 0.</span>
      </>
    )
  }

  const NoRecordFoundModal = ({ row }) => {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* CLOSE ICON */}
          <Grid
            container
            spacing={2}
          >
            <IconButton
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            container
            spacing={2}
          >
            {/* TOP DIVIDER */}
            <Grid
              item
              xs={12}
              sx={{ paddingTop: "15px !important" }}
            >
              <Divider className="divider-title"> {row.modalText === "exists" ? "NOTICE!" : "ERROR: INVALID ENTRY"}</Divider>
            </Grid>

            {/* TEXT */}
            <Grid
              item
              xs={12}
              sx={{ textAlign: "center" }}
            >
              {showModalText(row)}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  }

  const requestItemColumns = [
    { field: "nationalStockNumber", headerName: "NSN", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "partNumber", headerName: "Part #", minWidth: 100, align: "left", headerAlign: "left" },
    { field: "serialNumber", headerName: "Serial #", minWidth: 80, align: "left", editable: isEditable, headerAlign: "left" },
    { field: "nomenclature", headerName: "Nomenclature", minWidth: 200, align: "left", headerAlign: "left" },
    { field: "unitOfIssue", headerName: "UI", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "quantity", headerName: "Qty", type: "number", editable: isEditable, minWidth: 20, align: "left", headerAlign: "left" },
    {
      field: "currentBalance",
      headerName: "Current Balance",
      type: "number",
      minWidth: 20,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "conditionCode",
      headerName: "Condition Code",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "hazardousMaterielIndicatorCode",
      headerName: "HMIC",
      minWidth: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      type: "number",
      minWidth: 80,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      type: "number",
      minWidth: 80,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    { field: "location", headerName: "Location", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "propertyNumber", headerName: "Property #", minWidth: 80, align: "left", headerAlign: "left" },
    {
      field: "endBalance",
      headerName: "End Balance",
      type: "number",
      editable: isEditable,
      minWidth: 100,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
      valueFormatter: ({ value }) =>
        value != null
          ? Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 3,
              minimumFractionDigits: 0,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "removeItem",
      headerName: "Remove Item",
      minWidth: 80,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div className="actions-buttons">
            <RemoveCircleIcon
              sx={{ color: "darkred" }}
              className="delete-btn"
              onClick={async () => await handleRemoveRequestItem(params.id)}
            />
          </div>
        )
      },
    },
  ]

  let determineDisplayOfColumn = !partsSignature && !partsDate ? requestItemColumns : requestItemColumns.splice(-1)

  const determineCellEditability = (params) => {
    let isBulkItem = !params.row.serialNumber || params.row.serialNumber === "NA"
    let isSerialNum = params.field === "serialNumber"
    let isQty = params.field === "quantity"
    let isEndBal = params.field === "endBalance"

    if (!isPending) return false

    if (isSerialNum && partsSignature) return false
    else if (isSerialNum && !isBulkItem) return true
    else if (isSerialNum && isBulkItem) return false

    if ((isQty && !isBulkItem) || (isQty && partsSignature)) return false
    else if (isQty && isBulkItem && !partsSignature) return true

    if (isEndBal) return true
  }

  return (
    <>
      {open && <NoRecordFoundModal row={modifiedRow} />}
      <DataGridPremium
        initialState={{
          columns: {
            columnVisibilityModel: {
              currentBalance: !isPending ? false : true,
              removeItem: notPermitted ? false : true,
            },
          },
        }}
        rows={rows}
        columns={requestItemColumns}
        disableRowSelectionOnClick
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
        isCellEditable={determineCellEditability}
        editMode="row"
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={(e) => console.log(e)}
        onRowEditStart={() => handleDisableSubmitButton(true)}
        onRowEditStop={() => handleDisableSubmitButton(false)}
        loading={isLoading}
        density="compact"
        sx={{
          boxShadow: 3,
          border: "1px solid",
          borderColor: "rgba(16, 80, 117, 0.5)",
          "& .even-row.MuiDataGrid-row": {
            backgroundColor: "rgba(219, 231, 229, 0.35)",
            "&:hover, &.Mui-hovered": {
              backgroundColor: "rgba(84, 187, 253, 0.1)",
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable):focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)": {
            color: "grey",
          },
          "& .MuiDataGrid-cell--editable": {
            color: "black",
          },
          "& .MuiDataGrid-cell--editable:focus-within": {
            backgroundColor: "rgba(63, 221, 200, 0.15)",
          },
        }}
      />
    </>
  )
}

export default RequestItemsGrid
