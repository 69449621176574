import { useState } from "react"

// ** MUI
import { Box, Button, Divider, FormControl, Grid, IconButton, Modal, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { style } from "../utils"

export const CustomModal = ({ shouldOpen, handleConfirmDelete, pathname, title, reference }) => {
  const [open, setOpen] = useState(shouldOpen)
  const [reasoning, setReasoning] = useState("") // For pending forms.

  const isAPorWHView = !pathname.includes("inventory") && !pathname.includes("users") && !pathname.includes("dashboard") && pathname !== "/"
  const isUsersView = pathname.includes("users")
  const isPendingFormsView = pathname.includes("dashboard") || pathname === "/"

  const addBulletPoint = (e) => {
    if (e.key === "Enter") {
      setReasoning(reasoning + "• ")
    }
  }

  const showDeleteUserText = () => {
    return (
      <>
        <span>Are you sure you want to delete </span>
        <span className="highlight-blue">{reference}</span>
        <span>?</span>
      </>
    )
  }

  const showArchiveText = () => {
    return title.includes("Record") ? (
      <span>Are you sure you want to archive this record?</span>
    ) : (
      <>
        <span>Are you sure you want to archive </span>
        <span className="highlight-blue">{reference}</span>
        <span>?</span>
      </>
    )
  }

  const showPendingFormsText = () => {
    return (
      <>
        <span>Are you sure you'd like to cancel request </span>
        <span className="highlight-blue">{reference}</span>
        <span>? If so, please provide your reason(s) for cancelling below:</span>
      </>
    )
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleConfirmDelete(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">{title}</Divider>
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={12}
            sx={{ textAlign: "center" }}
          >
            {isUsersView ? showDeleteUserText() : isPendingFormsView ? showPendingFormsText() : showArchiveText()}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ textAlign: "center" }}
          >
            {isAPorWHView ? (
              <i>Note: All associated inventory will be archived as well.</i>
            ) : isPendingFormsView ? (
              <FormControl sx={{ minWidth: "100%", minHeight: "50%" }}>
                <TextField
                  name="reason"
                  id="reason"
                  type="text"
                  value={reasoning}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  multiline={true}
                  rows={8}
                  onChange={(e) => setReasoning(e.target.value)}
                  onKeyUp={addBulletPoint}
                  onFocus={() => setReasoning("• ")} // Add so first line is bulleted.
                />
              </FormControl>
            ) : null}
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={8.5}
          />
          <Grid
            item
            xs={3.5}
          >
            <Button
              variant="contained"
              color={isUsersView || isPendingFormsView ? "error" : "primary"}
              onClick={() => (isPendingFormsView ? handleConfirmDelete(true, reasoning) : handleConfirmDelete(true))}
              disabled={isPendingFormsView && reasoning.length == 0}
            >
              {isUsersView ? "Delete" : isPendingFormsView ? "Confirm" : "Archive"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
